import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getPostById, getMilestoneById } from '../functions/firebase';
import Post from '../components/post';
import MilestonePost from '../components/post-milestone';
import { useProfile } from '../assets/data/providerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SinglePostPage({ user }) {
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { postId } = useParams();
    const profileContext = useProfile();
    const navigate = useNavigate();

    const getColorIndex = (hobbyID) => {
        if (profileContext.profileInfo == null) {
          return 1;
        }
        const hobby = profileContext.profileInfo.hobbies.find((hobby) => hobby.id === hobbyID);
        return hobby.color_index;
      }

    useEffect(() => {
        const fetchItem = async () => {
            try {
                setLoading(true);
                // Try to fetch as a normal post first
                let fetchedItem = await getPostById(user.uid, postId);

                if (fetchedItem) {
                    setItem(fetchedItem);
                } else {
                    setError('Post or milestone not found');
                }
            } catch (error) {
                console.error("Error fetching item:", error);
                setError('Error fetching post or milestone');
            } finally {
                setLoading(false);
            }
        };

        fetchItem();
    }, [user.uid, postId]);

    const handleBackClick = () => {
        if (window.history.length > 2) {
            navigate(-1); // Go back in history
        } else {
            navigate(`/u/${user.uid}/profile/feed`); // Navigate to user's profile feed
        }
    };

    const handleSeeMorePosts = () => {
        navigate(`/u/${user.uid}/profile/feed`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!item) {
        return <div>Post or milestone not found</div>;
    }

    const commonProps = {
        user: user,
        title: item.title,
        content: item.content,
        location: item.location,
        category: item.hobby.hobbyName,
        color: getColorIndex(item.hobby.id),
        imageUrl: item.imageUrl,
        user: user,
        hobbyID: item.hobby.id,
        createdAt: item.postDate,
        clickable: false,
        isPrivate: item.privatePost
    };

    return (
        <div className="single-post-page">
            <div className='page-header-nav'>
                <Container fluid>
                    <Row>
                        <Col xs="2">
                            <div className='circ-btn subtext-gray' onClick={handleBackClick}>
                                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                            </div>
                        </Col>
                        <Col className='title' xs="8">
                            <span>{profileContext.profileInfo?.displayName || 'Post Details'}</span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content-below-nav'>
                {item.milestone ? (
                    <MilestonePost {...commonProps} />
                ) : (
                    <Post {...commonProps} />
                )}
                <Container fluid className="mt-4 mb-4">
                    <Row>
                        <Col className="text-center">
                            <Button variant="outline-primary" onClick={handleSeeMorePosts}>
                                See More Posts
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default SinglePostPage;