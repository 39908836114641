import React from 'react';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../style/hobbies.css';
import { hobbies } from '../assets/data/userdata';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProfile } from '../assets/data/providerContext';
import { allowWriteAccess } from '../functions/util_fn';

function HobbiesList(props) {

  const profileContext = useProfile();
  const profileInfo = profileContext == null ? null : profileContext.profileInfo;

  const navigate = useNavigate();
  var location = useLocation()

  // Given a hobby id, navigate to the hobby page
  const goToHobbyPage = (hid) => {
    navigate(`/u/${props.user.uid}/hobby/${hid}`, {
      state: {
        "origin_url": location.pathname
      }
    })
  }

  if (profileInfo?.hobbies) {
    console.log(profileInfo.hobbies);
  }

  return (
    <div className="hobbies-list">
      { profileInfo == null ? <div></div> :
      <Container fluid className="p-0">
        {profileInfo.hobbies.map((hobby, index) => (
          <Row className="g-0 m-0">
            <Col xs="12" className="p-0">
              <div key={index} className="hobby-card" onClick={() => goToHobbyPage(hobby.id)}>
                <header className=''
                  style={{
                    width: '100%',
                    backgroundImage: `url(${hobby.cover_photo_url || ''})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                <div className='black-gradient'>
                  <Container>
                    <Row>
                      <Col xs="11">
                      <div className={`post-count-label`}>
                          {hobby.post_count === 0 ? 
                            <span>No Posts</span> :
                            <span>{hobby.post_count} Posts</span>
                          }
                        </div>
                        <h2>{hobby.hobbyName}</h2>
                        <p style={{marginBottom: '16px'}}>{hobby.tagline || ''}</p>
                      </Col>
                      <Col xs="1" className='right-align subtext-gray'>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </header>
            </div>
            </Col>
          </Row>
        ))}
      </Container>}
    </div>
  );
}

export default HobbiesList;

// <div className="hobbies-list">
// { profileInfo == null ? <div></div> :
// <Container fluid>
//   {profileInfo.hobbies.map((hobby, index) => (
//   <Row key={index} className="hobby-row" onClick={(() => { goToHobbyPage(hobby.id) })}>
//       <Col xs="2" className="icon-col">
//           <div className={`hobby-category-circle color-${hobby.color_index || '1'}`}></div>
//       </Col>
//       <Col xs="9" className="text-col">
//         <div className="hobby-name">{hobby.hobbyName}</div>
//         { hobby.post_count === 0 ? <div className="subtext-gray">No Posts</div> :
//           <div className="post-count">{hobby.post_count} Posts</div>
//         }
//       </Col>
//       <Col xs="1" className='right-align subtext-gray'>
//         <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
//       </Col>
//   </Row>
//   ))}
//   {/* { allowWriteAccess(props.user) ? 
//   <Row>
//     <Col xs="12">
//       <div className='secondary-wide-btn'><FontAwesomeIcon icon="fa-solid fa-plus" /> Add Hobby</div>
//     </Col>
//   </Row> : <></>} */}
  
// </Container>}

// </div>

