import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNavigate } from 'react-router-dom';
import { useProfile } from '../assets/data/providerContext';
import { uploadPhoto, updateHobbyInfo, deleteHobby } from '../functions/firebase';
import { mpTrack } from '../functions/util_fn';

function EditHobby(props) {
    const profileContext = useProfile();

    const [hobbyName, setHobbyName] = useState(props.hobbyHeader.hobbyName || '');
    const [tagline, setTagline] = useState(props.hobbyHeader.tagline || '');
    const [coverPhotoUrl, setCoverPhotoUrl] = useState(props.hobbyHeader.cover_photo_url || '');
    const [newCoverPhoto, setNewCoverPhoto] = useState(null);
    const [colorIndex, setColorIndex] = useState(props.hobbyHeader.color_index || 1);
    const [isEdited, setIsEdited] = useState(false);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(props.hobbyHeader.startDate || '');
    const [aboutText, setAboutText] = useState(props.hobbyHeader.aboutText || '');
    const [aboutImageUrl, setAboutImageUrl] = useState(props.hobbyHeader.aboutImageUrl || '');
    const [newAboutImage, setNewAboutImage] = useState(null);
    const [gear, setGear] = useState(props.hobbyHeader.gear || []);
    const aboutImageInputRef = useRef(null);
    const [gearImages, setGearImages] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigate = useNavigate();

    const handleTaglineChange = (e) => {
        setTagline(e.target.value);
        setIsEdited(true);
    }

    const handleCoverPhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewCoverPhoto(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoverPhotoUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setIsEdited(true);
        }
    }

    const handleColorChange = (index) => {
        setColorIndex(index);
        setIsEdited(true);
    }

    const handleAboutImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewAboutImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAboutImageUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setIsEdited(true);
        }
    }

    const handleGearChange = (index, field, value) => {
        const updatedGear = [...gear];
        updatedGear[index][field] = value;
        setGear(updatedGear);
        setIsEdited(true);
    }

    const addGearItem = () => {
        setGear([...gear, { name: '', imageUrl: '', linkUrl: '', actionButton: 'View Item' }]);
        setIsEdited(true);
    }

    const removeGearItem = (index) => {
        const updatedGear = gear.filter((_, i) => i !== index);
        setGear(updatedGear);
        setIsEdited(true);
    }

    const handleGearImageChange = async (index, file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const updatedGear = [...gear];
                updatedGear[index].imageUrl = reader.result;
                setGear(updatedGear);
                
                setGearImages(prev => ({
                    ...prev,
                    [index]: file
                }));
            };
            reader.readAsDataURL(file);
            setIsEdited(true);
        }
    };

    const handleSave = async () => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                let finalCoverPhotoURL = coverPhotoUrl;
                let finalAboutImageURL = aboutImageUrl;

                if (newCoverPhoto) {
                    finalCoverPhotoURL = await uploadPhoto(newCoverPhoto, props.user.uid, 'hobby_cover_photos');
                }
                if (newAboutImage) {
                    finalAboutImageURL = await uploadPhoto(newAboutImage, props.user.uid, 'hobby_about_photos');
                }

                const updatedGear = [...gear];
                for (const [index, file] of Object.entries(gearImages)) {
                    const imageUrl = await uploadPhoto(file, props.user.uid, 'hobby_gear_photos');
                    updatedGear[index].imageUrl = imageUrl;
                }

                const updatedHobbyInfo = {
                    tagline,
                    cover_photo_url: finalCoverPhotoURL,
                    color_index: colorIndex,
                    startDate,
                    aboutText,
                    aboutImageUrl: finalAboutImageURL,
                    gear: updatedGear
                };

                await updateHobbyInfo(props.user.uid, props.hobbyHeader.id, updatedHobbyInfo);
                await profileContext.refreshProfileInfo();
                setIsEdited(false);
                props.toggleShowEditPage();
            } catch (error) {
                console.error("Error updating hobby:", error);
            }
            setIsLoading(false);
        }
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    }

    const renderCategoryCircles = (count) => {
        return Array.from({ length: count }, (_, i) => (
            <div key={i} className={`color-selection ${colorIndex === i + 1 ? 'selected' : ''}`}>
                <div
                    className={`post-category-circle color-${i + 1}`}
                    onClick={() => handleColorChange(i + 1)}
                ></div>
            </div>
        ));
    };

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        setIsLoading(true);
        try {
            const success = await deleteHobby(props.user.uid, props.hobbyHeader.id);
            if (success) {
                await profileContext.refreshProfileInfo();
                navigate(-1);
            }
        } catch (error) {
            console.error("Error deleting hobby:", error);
        }
        setIsLoading(false);
        setShowDeleteModal(false);
    };

    return (
        <div className={isLoading ? 'loading-overlay' : ''}>
            <div className='page-header-nav'>
                <Container fluid>
                    <Row>
                        <Col xs="2">
                            <div className={`circ-btn subtext-gray ${isLoading ? 'disabled' : ''}`} 
                                 onClick={!isLoading ? props.toggleShowEditPage : null}>
                                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                            </div>
                        </Col>
                        <Col className='title' xs="8">
                            <span>Customize Hobby</span>
                        </Col>
                        <Col xs="2">
                            <div className={`circ-btn primary-text-color ${(!isEdited || isLoading) && 'disabled'}`}>
                                <p onClick={isEdited && !isLoading ? handleSave : null}><b>Save</b></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid className='content-below-nav'>
                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span>Hobby Name</span>
                            <h2>{hobbyName}</h2>
                        </Col>
                    </Row>
                </div>
                <div className="post">
                    <Row className="justify-content-center">
                        <Col xs="12">
                            <div 
                                style={{
                                    height: '220px',
                                    width: '100%',
                                    backgroundImage: `url(${coverPhotoUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            ></div>
                        </Col>
                        <Col xs="12" className="text-center">
                            <p className='primary-text-color' onClick={triggerFileInput} style={{ cursor: 'pointer', paddingTop: '15px' }}>Change Cover Photo</p>
                            <input 
                                type="file" 
                                ref={fileInputRef} 
                                style={{ display: 'none' }} 
                                onChange={handleCoverPhotoChange} 
                            />
                        </Col>
                    </Row>
                </div>
                <div className="post">
                    <Row>
                        <Col xs="6">
                            <span>Header Tagline</span>
                        </Col>
                        <Col xs="6" style={{textAlign: 'right'}}>
                            <span>{tagline.length}/50</span>
                        </Col>
                        <Col xs="12">
                            <input 
                                type='text' 
                                value={tagline} 
                                onChange={handleTaglineChange}
                                maxLength="50"
                            />
                        </Col>
                    </Row>
                </div>
                
                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span>Hobby Color</span>
                        </Col>
                        <Col xs="12">
                            {renderCategoryCircles(7)}
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                        <h3 style={{marginBottom: '24px'}}>About Hobby Section</h3>
                            <span>Hobby Start Date</span>
                            <input 
                                type="date" 
                                value={startDate}
                                onChange={(e) => {
                                    setStartDate(e.target.value);
                                    setIsEdited(true);
                                }}
                                style={{marginLeft: '12px'}}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span>Your Hobby's Story</span>
                            <textarea
                                placeholder="I got into this because..."
                                value={aboutText}
                                onChange={(e) => {
                                    setAboutText(e.target.value);
                                    setIsEdited(true);
                                }}
                                rows={3}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span>About Hobby Header Image</span>
                            {aboutImageUrl && 
                                <div 
                                    style={{
                                        height: '220px',
                                        width: '100%',
                                        backgroundImage: `url(${aboutImageUrl})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}
                                ></div>
                            }
                            <p className='primary-text-color' onClick={() => aboutImageInputRef.current.click()}>
                                Change About Image
                            </p>
                            <input 
                                type="file" 
                                ref={aboutImageInputRef} 
                                style={{ display: 'none' }} 
                                onChange={handleAboutImageChange} 
                            />
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span>Gear</span>
                            {gear.map((item, index) => (
                                <div key={index} 
                                     className="gear-item" 
                                     style={{
                                         marginTop: '16px',
                                         border: 'solid 1px #DEDEE4',
                                         borderRadius: '10px',
                                         padding: '16px'
                                     }}>
                                    <input
                                        type="text"
                                        placeholder="Item name"
                                        value={item.name}
                                        onChange={(e) => handleGearChange(index, 'name', e.target.value)}
                                    />
                                    
                                    {item.imageUrl && (
                                        <div 
                                            style={{
                                                height: '220px',
                                                width: '100%',
                                                backgroundImage: `url(${item.imageUrl})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                marginTop: '12px',
                                                marginBottom: '12px',
                                                borderRadius: '5px'
                                            }}
                                        ></div>
                                    )}
                                    
                                    <p className='primary-text-color' 
                                       onClick={() => document.getElementById(`gear-image-${index}`).click()}
                                       style={{marginTop: '12px', marginBottom: '12px'}}>
                                        {item.imageUrl ? 'Change Image' : 'Add Image'}
                                    </p>
                                    <input
                                        id={`gear-image-${index}`}
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleGearImageChange(index, e.target.files[0])}
                                    />
                                    
                                    <input
                                        type="text"
                                        placeholder="Link URL"
                                        value={item.linkUrl}
                                        onChange={(e) => handleGearChange(index, 'linkUrl', e.target.value)}
                                    />
                                    
                                    <button 
                                        className="secondary-wide-btn"
                                        onClick={() => removeGearItem(index)}
                                        style={{
                                            width: '100%',
                                            marginTop: '12px',
                                            color: '#dc3545',  // Red color for delete action
                                            borderColor: '#dc3545'
                                        }}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-trash" style={{marginRight: '8px'}} />
                                        Remove Item
                                    </button>
                                </div>
                            ))}
                            <button 
                                className="secondary-wide-btn"
                                onClick={addGearItem}
                                style={{width: '100%', marginTop: '16px'}}
                            >
                                Add Gear Item
                            </button>
                        </Col>
                    </Row>
                </div>

                <div className="post">
                    <Row>
                        <Col xs="12">
                            <button 
                                className="secondary-wide-btn"
                                onClick={handleDeleteClick}
                                style={{
                                    width: '100%',
                                    marginTop: '16px',
                                    color: '#dc3545',
                                    borderColor: '#dc3545'
                                }}
                            >
                                <FontAwesomeIcon icon="fa-solid fa-trash" style={{marginRight: '8px'}} />
                                Delete Hobby
                            </button>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Hobby</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this hobby? This action cannot be undone and will delete all associated posts.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="secondary-wide-btn"
                        onClick={() => setShowDeleteModal(false)}
                        style={{
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}
                    >
                        Cancel
                    </button>
                    <button 
                        className="secondary-wide-btn"
                        onClick={handleConfirmDelete}
                        style={{
                            color: '#dc3545',
                            borderColor: '#dc3545',
                            paddingLeft: '10px', 
                            paddingRight: '10px'
                        }}
                    >
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EditHobby;

