import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNavigate } from 'react-router-dom';
import { useProfile } from '../assets/data/providerContext';
import { updateProfileInfo, uploadProfilePicture, uploadPhoto, userSignOut, processAllUsersPosts } from '../functions/firebase';
import { hardRefreshPage, mpTrack } from '../functions/util_fn';
import { CONSTANTS } from '../assets/data/constants';

function EditProfile(props) {
    const navigate = useNavigate();
    const profileContext = useProfile();
    const profileInfo = profileContext == null ? null : profileContext.profileInfo;

    const [name, setName] = useState('');
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [newProfilePic, setNewProfilePic] = useState(null);
    const [location, setLocation] = useState('');
    const [isEdited, setIsEdited] = useState(false);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (profileInfo) {
            setName(profileInfo.displayName);
            setLocation(profileInfo.location);
            setProfilePicUrl(profileInfo.profile_pic_url);
        }
    }, [profileInfo]);

    const goBack = () => {
        navigate(-1);
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
        setIsEdited(true);
    }

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
        setIsEdited(true);
    }

    const handleProfilePicChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewProfilePic(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePicUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setIsEdited(true);
        }
    }

    const handleSave = async () => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                // Upload new profile picture if it exists
                let profilePicURL = profilePicUrl;
                if (newProfilePic) {
                    profilePicURL = await uploadPhoto(newProfilePic, props.user.uid, 'profile_pictures');
                }
                
                let updatedInformation = { displayName: name, location, profile_pic_url: profilePicURL }

                await updateProfileInfo(props.user.uid, updatedInformation);
                mpTrack('Profile Updated', updatedInformation);
                await profileContext.refreshProfileInfo();
                setIsEdited(false);
                goBack();
            } catch (error) {
                console.error("Error updating profile:", error);
            }
            setIsLoading(false);
        }
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    }

    // Function to sign out user and navigate to home page
    const executeUserSignout = async () => {
        mpTrack('User Signout');
        var signedOut = await userSignOut()
        if(signedOut){
            navigate('/');
        }
    }

    return (
        <div className={isLoading ? 'loading-overlay' : ''}>
            <div className='page-header-nav'>
                <Container fluid>
                    <Row>
                        <Col xs="2">
                            <div className={`circ-btn subtext-gray ${isLoading ? 'disabled' : ''}`} 
                                 onClick={!isLoading ? goBack : null}>
                                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                            </div>
                        </Col>
                        <Col className='title' xs="8">
                            <span>Edit Profile</span>
                        </Col>
                        <Col xs="2">
                            <div className={`circ-btn primary-text-color ${(!isEdited || isLoading) && 'disabled'}`}>
                                <p onClick={isEdited && !isLoading ? handleSave : null}><b>Save</b></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid className='content-below-nav'>
                <div className="post">
                    <Row className="justify-content-center">
                        <Col xs="5" className="text-center">
                            <div style={{
                                width: '120px', // Set the desired width and height to the same value
                                height: '120px',
                                backgroundImage: `url(${profilePicUrl})`,
                                backgroundSize: 'cover', // Cover the entire div
                                backgroundPosition: 'center', // Center the image
                                backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                                borderRadius: '50%' // Make the div a circle    
                            }}></div>
                            {/* <img className='circ-image' src={profilePicUrl} alt="Profile" /> */}
                        </Col>
                        <Col xs="12" className="text-center">
                            <p className='primary-text-color' onClick={triggerFileInput} style={{ cursor: 'pointer', paddingTop: '15px' }}>Change Profile Picture</p>
                            <input 
                                type="file" 
                                ref={fileInputRef} 
                                style={{ display: 'none' }} 
                                onChange={handleProfilePicChange} 
                            />
                        </Col>
                    </Row>
                </div>
                <div className="post">
                    <Row>
                        <Col xs="1">
                            <span>Name</span>
                        </Col>
                        <Col xs="12">
                            <input 
                                type='text' 
                                value={name} 
                                onChange={handleNameChange}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="post">
                    <Row>
                        <Col xs="1">
                            <span>Location</span>
                        </Col>
                        <Col xs="12">
                            <input 
                                type='text' 
                                value={location} 
                                onChange={handleLocationChange}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span style={{color:'#95969A'}}>App Version {CONSTANTS.APP_VERSION} - <span onClick={()=>{hardRefreshPage()}} className='primary-text-color'>Refresh App</span></span>
                        </Col>
                    </Row>
                </div>
                <div className="post">
                    <Row>
                        <Col xs="12">
                            <span onClick={()=>{executeUserSignout()}} style={{ color: 'red' }}>Logout</span>
                        </Col>
                    </Row>
                </div>
                
            </Container>
        </div>
    );
}

export default EditProfile;
