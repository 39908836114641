import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../style/popupMenu.css';
import { allowWriteAccess } from '../functions/util_fn';

function PopupOptionsMenu(props) {
    const menuRef = useRef(null);

    const closeOptions = () => {
        // Your closeOptions logic here
        props.toggleShowPopupOptions();
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            closeOptions();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='popupMenu'>
            <div className='bg-mask'>
                <div className='menu-container' ref={menuRef}>
                    {props.buttons.map((button, index) => (
                        (button.requiresWriteAccess ? allowWriteAccess(props.user) : true) && (
                            <div key={index} className="post" onClick={button.onClick}>
                                <Container fluid>
                                    <Row>
                                        <Col xs="1">
                                            <FontAwesomeIcon style={{ color: button.color }} icon={button.icon} />
                                        </Col>
                                        <Col xs="11">
                                            <span style={{ color: button.color }}>{button.text}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PopupOptionsMenu;
