import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateInput } from 'rsuite';
import 'rsuite/DateInput/styles/index.css';

import '../style/post.css';
import { uploadPhoto } from '../functions/firebase';
import { getFormattedDate } from '../functions/util_fn';

const AUTOSAVE_DELAY_MS = 0; // 3 seconds

function AttributeInput(props) {

    const [value, setValue] = useState(() => {
        if (props.type === 'checkbox') {
            return props.initialValue || false;
        }
        return props.initialValue || '';
    });
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const fileInputRef = useRef(null);
    const debounceTimer = useRef(null);

    useEffect(() => {
        if (props.initialValue !== undefined) {
            setValue(props.initialValue);
        }
    }, [props.initialValue]);

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const handleAddPhotoClick = () => {
        fileInputRef.current.click();
    };

    // Function to handle photo upload
    // This function is called when a user selects a photo to upload
    // It uploads the photo to Firebase Storage and returns the URL
    // A loading message is displayed while the photo is uploading
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            props.showLoadingMessage('Uploading Photo...');
            var fileURL = await uploadPhoto(file, props.user.uid,'photos')
            props.addPostInfo("post-photo", fileURL);
            props.hideLoadingMessage();
        }
    };

    const handleCheckboxChange = () => {
        const newValue = !value;
        setValue(newValue);
        props.addPostInfo(props.title, newValue);
    };

    const showInput = () => {
        setEditing(true);
    }

    const handleTextChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        
        debounceTimer.current = setTimeout(() => {
            props.addPostInfo(props.title, newValue);
            // setEditing(false);
        }, AUTOSAVE_DELAY_MS);
    };

    const handleDateChange = (dateObject) => {
        const formattedDate = getFormattedDate(dateObject);
        setValue(formattedDate);
        
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        
        debounceTimer.current = setTimeout(() => {
            props.addPostInfo(props.title, formattedDate);
            // setEditing(false);
        }, AUTOSAVE_DELAY_MS);
    };

    useEffect(() => {
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        };
    }, []);

    const handleDeletePhoto = () => {
        props.addPostInfo("post-photo", null);
        setValue(null); // Add this state to track if photo is uploaded
    };

    const renderEditingContent = () => {
        switch (props.type) {
            case 'text':
                return (
                    <>
                        <Col xs="11">
                            <input 
                                type="text" 
                                value={value} 
                                onChange={handleTextChange}
                                ref={inputRef} 
                            />
                        </Col>
                    </>
                );
            case 'date':
                return (
                    <>
                        <Col xs="11">
                            <DateInput 
                                format="MM/dd/yyyy" 
                                size="lg" 
                                ref={inputRef} 
                                onChange={handleDateChange}
                            />
                        </Col>
                    </>
                );
            default:
                return null;
        }
    };

    const renderStaticContent = () => {
        switch (props.type) {
            case 'text':
                return (
                    <>
                        <Col xs="10" onClick={showInput}>
                            <span>{ value ? value : props.title }</span>
                        </Col>
                        <Col xs="1" onClick={showInput}>
                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        </Col>
                    </>
                )
            case 'date':
                return (
                    <>
                        <Col xs="10" onClick={showInput}>
                            <span>{ value ? value : props.title }</span>
                        </Col>
                        <Col xs="1" onClick={showInput}>
                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        </Col>
                    </>
                )
            case 'image':
                return (
                    <>
                        <Col xs="10" onClick={handleAddPhotoClick}>
                            <span>{value ? 'Change Photo' : props.title}</span>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </Col>
                        <Col xs="1">
                            {value ? (
                                <FontAwesomeIcon 
                                    icon="fa-solid fa-trash" 
                                    onClick={handleDeletePhoto}
                                />
                            ) : (
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            )}
                        </Col>
                    </>
                )
            case 'checkbox':
                return (
                    <>
                        <Col xs="10" onClick={handleCheckboxChange}>
                            <span>{props.title}</span>
                        </Col>
                        <Col xs="1">
                            <input
                                type="checkbox"
                                checked={value}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                    </>
                );
            default:
                return null;
        }
    }
    

  return (
    <div className="post">
        <Container fluid>
            <Row>
                <Col xs="1">
                    <FontAwesomeIcon icon={`fa-solid ${props.icon}`} />
                </Col>
                { editing ?
                    renderEditingContent() : renderStaticContent() 
                }
            </Row>
        </Container>
    </div>
  );
}

export default AttributeInput;
