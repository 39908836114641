import React, { useState, useEffect } from 'react';
import {  Row, Col, Container } from 'react-bootstrap';

function HobbyHeader(props) {

    const [headerData, setHeaderData] = useState(props.headerData);

    useEffect(() => {
        setHeaderData(props.headerData);
    }, [props.headerData]);

    const getCoverPhotoUrl = () => {
        if (headerData.cover_photo_url) {
            return headerData.cover_photo_url;
        } else {
            return '';
        }
    }

  return (
    <>
    <header className='hobby-header'
        style={{
            width: '100%',
            backgroundImage: `url(${getCoverPhotoUrl()})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}
        >
        <div className='black-gradient'>
            <Container>
                <Row>
                    <Col xs="12">
                        <h1>{headerData.hobbyName}</h1>
                        <p>{headerData.tagline || ''}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    </header>
        {/* <section className='stat-highlights'>
            <Container>
                <Row>
                    {headerData.stats.map((stat, index) => (
                        <Col xs="4" key={index}>
                            <div className='stat'>
                                <div className='stat-label'>{stat.label}</div>
                                <div className='stat-number'>{stat.value}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section> */}
    </>
  );
}

export default HobbyHeader;
