import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Container, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faHeart, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
import AuthForm from './components/authForm';
import { userSignOut } from './functions/firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import hobbiLogo from './assets/images/hobbi-logo-nobg.png';
import AppLanding from './pages/appLanding';

library.add(faUsers, faHeart, faCalendarAlt, faApple, faAndroid);

function Landing(props) {
  const navigate = useNavigate();
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [showDownloadInstructions, setShowDownloadInstructions] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    // Check if the app is running in standalone mode (PWA)
    const standalone = window.matchMedia('(display-mode: standalone)').matches ||
                      window.navigator.standalone || // iOS Safari
                      document.referrer.includes('android-app://');
    
    setIsStandalone(standalone);
    if (standalone) {
      setShowAuthForm(true);
    }

    // Optional: Listen for changes in display mode
    const mediaQuery = window.matchMedia('(display-mode: standalone)');
    const handleChange = (e) => {
      setIsStandalone(e.matches);
      if (e.matches) {
        setShowAuthForm(true);
      }
    };
    mediaQuery.addListener(handleChange);

    return () => mediaQuery.removeListener(handleChange);
  }, []);

  const goToHome = () => {
    if (props.user) {
      navigate(`/u/${props.user.uid}`, {});
    } else {
      console.log('User not logged in');
    }
  };

  useEffect(() => {
    if (props.user) {
      navigate(`/u/${props.user.uid}`, { replace: true });
    }
  }, [props.user, navigate]);

  const handleAuthSuccess = (user) => {
    navigate(`/u/${user.uid}`, { replace: true });
  };

  const executeUserSignout = async () => {
    var signedOut = await userSignOut()
    if(signedOut){
        navigate('/');
    }
  }

  const DownloadInstructions = () => (
    <Row className="justify-content-center mb-5">
      <Col xs="12" md="8" className="text-center">
        <h3 className="mb-4">Install Hobbiist on your device</h3>
        
        <Card className="mb-4">
          <Card.Header>
            <h4><FontAwesomeIcon icon={faApple} className="me-2" />iOS Instructions</h4>
          </Card.Header>
          <Card.Body>
            <ol className="text-start">
              <li>Open Safari and visit hobbi.ist</li>
              <li>Tap the Share button (box with arrow) at the bottom of the screen</li>
              <li>Scroll down and tap "Add to Home Screen"</li>
              <li>Tap "Add" in the top right corner</li>
            </ol>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header>
            <h4><FontAwesomeIcon icon={faAndroid} className="me-2" />Android Instructions</h4>
          </Card.Header>
          <Card.Body>
            <ol className="text-start">
              <li>Open Chrome and visit hobbi.ist</li>
              <li>Tap the three dots menu in the top right</li>
              <li>Tap "Add to Home screen"</li>
              <li>Tap "Add" to confirm</li>
            </ol>
          </Card.Body>
        </Card>

        <Button onClick={() => setShowDownloadInstructions(false)} variant="outline-primary">
          Back to Home
        </Button>
      </Col>
    </Row>
  );
  return (
    <>
      {isStandalone ? (
        <AppLanding 
          onAuthSuccess={handleAuthSuccess}
          setShowAuthForm={setShowAuthForm}
        />
      ) : (
        <div className="App">
          <Container>
            {props.user ? (
              <Row className="mb-5">
                <Col className="text-center">
                  <Button onClick={goToHome} className="me-3">Go To Profile</Button>
                  <Button onClick={executeUserSignout} variant="outline-primary">Logout</Button>
                </Col>
              </Row>
            ) : (
              <>
              <Row className="justify-content-center mb-5">
                      <Col xs="12" className="text-center">
                        <img 
                          src={hobbiLogo} 
                          alt="Hobbi Logo"
                          style={{
                            maxWidth: '200px',
                            marginTop: '24px',
                            marginBottom: '16px'
                          }}
                        />
                        <h1 className="display-4">Hobbiist</h1>
                        <h2 className="lead">Share your passions. Find your tribe.</h2>
                      </Col>
                    </Row>
                {showDownloadInstructions ? (
                  <DownloadInstructions />
                ) : showAuthForm ? (
                  <AuthForm
                    onAuthSuccess={handleAuthSuccess}
                  />
                ) : (
                  <>
                    

                    <Row className="justify-content-center mb-5">
                      <Col xs="12" className="text-center">
                        <Button onClick={() => setShowDownloadInstructions(true)} variant="primary" className="mb-4 primary-btn-fill">
                          Download
                        </Button>
                        <div>
                          <Button onClick={() => setShowAuthForm(true)} variant="link">
                            Sign up / Sign in
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-5">
                      <Col xs="12" className="text-center">
                        <h3>Discover the power of shared interests</h3>
                        <p>Hobbiist is the perfect platform for hobby enthusiasts to connect, share, and grow.</p>
                      </Col>
                    </Row>

                    <Row className="mb-5">
                      <Col md={4}>
                        <Card className="h-100">
                          <Card.Body className="text-center">
                            <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" />
                            <Card.Title>Connect</Card.Title>
                            <Card.Text>Find like-minded individuals who share your passions and hobbies.</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="h-100">
                          <Card.Body className="text-center">
                            <FontAwesomeIcon icon={faHeart} size="3x" className="mb-3" />
                            <Card.Title>Share</Card.Title>
                            <Card.Text>Showcase your projects, experiences, and achievements with the community.</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="h-100">
                          <Card.Body className="text-center">
                            <FontAwesomeIcon icon={faCalendarAlt} size="3x" className="mb-3" />
                            <Card.Title>Meet</Card.Title>
                            <Card.Text>Organize and join meetups, workshops, and events with fellow enthusiasts.</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="mb-5">
                      <Col xs="12" className="text-center">
                        <h4>Popular Hobbies on Hobbiist</h4>
                        <p>Photography • Cooking • Gardening • Hiking • Painting • Music • Crafting • Yoga • Gaming • Reading</p>
                        <Button as={Link} to="https://hobbi.ist/u/3cKlaPhrUNceS5dLP1bGKqZ95gs1/profile/feed" variant="outline-primary">
                          See an Example Profile
                        </Button>
                      </Col>
                    </Row>

                    <Row className="mb-5">
                      <Col xs="12" className="text-center">
                        <h3>Ready to dive in?</h3>
                        <p>Join Hobbiist today and start connecting with people who share your interests!</p>
                        <Button onClick={() => setShowDownloadInstructions(true)} variant="primary" className="mb-4 primary-btn-fill">
                          Download
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Container>
        </div>
      )}
    </>
  );
}

export default Landing;