import React, { useState, useEffect } from 'react';

import { Row, Col, Container, Button } from 'react-bootstrap';

import ProfileHeader from '../components/profileHeader';
import { Outlet, useLocation, NavLink } from 'react-router-dom';

function Profile(props) {

  const location = useLocation();

  const [isSticky, setIsSticky] = useState(false);
  const [tab, setTab] = useState('story');

  const changeTab = (tab) => {
    setTab(tab);
  }

  const getActiveTab = () => {
    if (location.pathname.includes('feed')) {
      return 'feed';
    }
    if (location.pathname.includes('hobbies')) {
      return 'hobbies';
    }
    return '';
  };

  const activeTab = getActiveTab();

  return (
    <>
      <ProfileHeader user={props.user}/>
      <div className={isSticky ? 'mid-nav top sticky-nav' : 'mid-nav top'}>
          <Container fluid>
          <Row>
              <Col xs="12">
              <NavLink to="feed"><div className={activeTab == 'feed' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('story')}}>Story</div></NavLink>
              <NavLink to="hobbies"><div className={activeTab == 'hobbies' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('hobbies')}}>Hobbies</div></NavLink>
              </Col>
          </Row>
          </Container>
      </div>
      <Outlet></Outlet> 
    </>
  );
}

export default Profile;
