import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import AuthForm from '../components/authForm';
import hobbiLogo from '../assets/images/hobbi-logo-nobg.png';
import HobbyIconCarousel from '../components/hobbyIconCarousel';

function AppLanding({ onAuthSuccess }) {
  useEffect(() => {
    if (window.navigator.standalone) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.height = '100%';
    }

    return () => {
      if (window.navigator.standalone) {
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.height = '';
      }
    };
  }, []);

  return (
    <div style={{
      background: `linear-gradient(180deg, #FFFFFF 0%, #13B2B3 85%, #FE876F 100%)`,
      minHeight: '100vh',
      padding: '20px',
      position: window.navigator.standalone ? 'fixed' : 'static',
      width: '100%',
      height: '100vh',
      overscrollBehavior: 'none'
    }}>
      <Row className="justify-content-center">
        <Col xs="12" className="text-left">
          <img 
            src={hobbiLogo} 
            alt="Hobbi Logo"
            style={{
              width: '80px',
              marginTop: '24px',
              marginBottom: '16px'
            }}
          />
          <h1 className="display-4" style={{
            color: '#2A4B4B',
            fontSize: '3.5rem',
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '700'
          }}>Hobbiist</h1>
          <h2 className="lead" style={{color: '#2A4B4B', fontWeight: '400'}}>Share your passions. Find your tribe.</h2>
        </Col>
      </Row>
      <HobbyIconCarousel />
      <Row className="justify-content-center" style={{
        position: 'fixed',
        bottom: '40px',
        left: '0',
        right: '0',
        margin: '0'
      }}>
        <Col xs="12" md="6">
          <AuthForm onAuthSuccess={onAuthSuccess} />
        </Col>
      </Row>
    </div>
  );
}

export default AppLanding;
