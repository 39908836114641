import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas, faUser, faCirclePlus, faLightbulb, faLocationDot, faEllipsis } from '@fortawesome/free-solid-svg-icons'

import Feed from '../components/feed';
import AboutHobby from '../components/aboutHobby';
import HobbyHeader from '../components/hobbyHeader';
import PopupOptionsMenu from '../components/popupOptionsMenu';
import { useProfile } from '../assets/data/providerContext';
import EditHobby from './editHobby';

import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { getPostsByHobby } from '../functions/firebase';
import { getHobbyInfo, getMilestonesOnly, handleShareLink } from '../functions/util_fn';

function HobbyPage(props) {

    const [isSticky, setIsSticky] = useState(false);
    const [tab, setTab] = useState('story');
    const [postsData, setPostsData] = useState(null);
    const [hobbyHeader, setHobbyHeader] = useState({});
    const [showHobbyOptions, setShowHobbyOptions] = useState(false);
    const [showEditPage, setShowEditPage] = useState(false);

    const profileContext = useProfile();
    const profileInfo = profileContext == null ? null : profileContext.profileInfo;

    var location = useLocation();

    const navigate = useNavigate();

    const hobbyHeaderRef = useRef(null);

    useEffect(() => {
        const getHobbyPosts = async (hobbyID) => {
            const posts = await getPostsByHobby(props.user.uid, hobbyID);
            setPostsData(posts);
        }

        var hobbyID = null;

        const regex = /\/u\/[^\/]+\/hobby\/([^\/]+)/;
        const match = location.pathname.match(regex);
        hobbyID = match ? match[1] : null;

        getHobbyPosts(hobbyID);

        var hobbyInfo = {
            name: ""
        }
        if(profileInfo){
            hobbyInfo = getHobbyInfo(hobbyID, profileInfo.hobbies);
        }
        setHobbyHeader(hobbyInfo);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (hobbyHeaderRef.current) {
                const headerRect = hobbyHeaderRef.current.getBoundingClientRect();
                setIsSticky(headerRect.bottom <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        var hobbyID = null;

        const regex = /\/u\/[^\/]+\/hobby\/([^\/]+)/;
        const match = location.pathname.match(regex);
        hobbyID = match ? match[1] : null;

        var hobbyInfo = {
            name: ""
        }
        if(profileInfo){
            hobbyInfo = getHobbyInfo(hobbyID, profileInfo.hobbies);
        }
        setHobbyHeader(hobbyInfo);
    },[profileInfo])

    const changeTab = (tab) => {
        setTab(tab);
    }

    const goBackToOrigin = () => {
        if(location.state){
            navigate(location.state.origin_url)
        } else {
            navigate(`/u/${props.user.uid}/profile/feed`)
        }
    }

    const toggleShowHobbyOptions = () => {
        setShowHobbyOptions(!showHobbyOptions);
    }

    const toggleShowEditPage = () => {
        setShowEditPage(!showEditPage);
        if(showHobbyOptions){
            setShowHobbyOptions(false);
        }
    }

    const handleShareHobbyPage = async () => {
        await handleShareLink();
        toggleShowHobbyOptions();
        toast("Copied link", {
            autoClose: 3000,
            hideProgressBar: false,
        } );
    }

    const hobbyOptionsMenu = [
        {
            icon: "fa-solid fa-pen-ruler",
            text: "Customize Hobby",
            onClick: () => {
                toggleShowEditPage();
            },
            requiresWriteAccess: true
        },
        {
            icon: "fa-solid fa-share-from-square",
            text: "Share Hobby Page",
            onClick: () => {
                handleShareHobbyPage();
            },
            requiresWriteAccess: false
        }
    ]

    return (
        <div>
            {showEditPage ? <EditHobby user={props.user} hobbyHeader={hobbyHeader} toggleShowEditPage={toggleShowEditPage} /> : 
            <>
                <div className='page-header-nav'>
                    <Container fluid>
                        <Row>
                            <Col xs="2">
                                <div className='circ-btn subtext-gray' onClick={goBackToOrigin}>
                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                                </div>
                            </Col>
                            <Col className='title' xs="8">
                                <span>{isSticky ? hobbyHeader.hobbyName : (profileInfo ? profileInfo.displayName : "")}</span>
                            </Col>
                            <Col xs="2" onClick={()=>{toggleShowHobbyOptions()}}>
                                <div className='circ-btn subtext-gray'>
                                    <FontAwesomeIcon icon="fa-solid fa-ellipsis" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div ref={hobbyHeaderRef}>
                    <HobbyHeader headerData={hobbyHeader} />
                </div>
                <div className={isSticky ? 'mid-nav with-header sticky-nav' : 'mid-nav with-header'}>
                    <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className={tab == 'story' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('story')}}>Latest</div>
                            <div className={tab == 'hobbies' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('hobbies')}}>Milestones</div>
                            {/* <div className={tab == 'stats' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('stats')}}>Stats</div> */}
                            <div className={tab == 'about' ? 'nav-btn active' : 'nav-btn'} onClick={() => {changeTab('about')}}>About</div>
                        </Col>
                    </Row>
                    </Container>
                </div>
                {showHobbyOptions ?
                    <PopupOptionsMenu
                        user={props.user}
                        toggleShowPopupOptions={toggleShowHobbyOptions}
                        buttons={hobbyOptionsMenu}
                    />
                    : null
                }
                <Outlet></Outlet>
                {(() => {
                    switch (tab) {
                        case 'story':
                            return <Feed posts={postsData} user={props.user}/>;
                        case 'hobbies':
                            return <Feed posts={getMilestonesOnly(postsData)} user={props.user}/>;
                        case 'stats':
                            return <div className='post' style={{"backgroundColor": "#e9f1f6"}}><b>Coming Soon:</b> Keep track of anything and everything related to {hobbyHeader.hobbyName}!</div>;
                        case 'about':
                            return <AboutHobby user={props.user} hobbyHeader={hobbyHeader} />;
                        default:
                    }
                })()}
            </>
            }
        </div>
    );
}

export default HobbyPage;
