import React, { useState, useEffect } from 'react';
import { getAllPosts } from '../functions/firebase';
import Feed from './feed';

function AllPostsFeed(props) {

  const [allPosts, setAllPosts] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      const posts = await getAllPosts(props.user.uid);
      setAllPosts(posts);
    }

    if(props.user != null) { getPosts() }
  }, []);

  return (
    <Feed posts={allPosts} user={props.user}></Feed>
  );
}

export default AllPostsFeed;
