import React, { useState } from 'react';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../style/post.css';
import { getTimeFromNow, handleShareLink  } from '../functions/util_fn';
import PopupOptionsMenu from './popupOptionsMenu';
import { deletePost } from '../functions/firebase';

const CONTENT_TRUNCATE_LENGTH = 178;

function Post({ postID, user, title, content, createdAt, daysAgo, location, category, color, imageUrl, hideMenu, clickable, hobbyID, isPrivate }) {
    const navigate = useNavigate();
    var URLlocation = useLocation();
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [showFullContent, setShowFullContent] = useState(false);

    const goToHobbyPage = () => {
        const targetUrl = `/u/${user.uid}/hobby/${hobbyID}`;
        
        // Check if the current URL is different from the target URL
        if (URLlocation.pathname !== targetUrl) {
            navigate(targetUrl, {
                state: {
                    "origin_url": URLlocation.pathname
                }
            });
        } else {
            console.log('Already on the target URL');
        }
    }

    const toggleOptionsModal = () => {
        setShowOptionsModal(!showOptionsModal);
    }

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    }

    const shortenedContent = content.length > CONTENT_TRUNCATE_LENGTH ? 
        content.slice(0, CONTENT_TRUNCATE_LENGTH) + '...' : content;

    const handleSharePostLink = async () => {
        const postUrl = `${window.location.origin}/u/${user.uid}/post/${postID}`;
        await handleShareLink(postUrl);
        toast("Copied link", {
            autoClose: 3000,
            hideProgressBar: false,
        });
    }

    const handleDeletePost = async () => {
        await deletePost(user.uid, postID);
        // Refresh the page on the same URL
        window.location.reload();
    }

    const postMenuOptions = [
        {
            icon: "fa-solid fa-share-from-square",
            text: "Share Post Link",
            onClick: () => {
                handleSharePostLink();
            },
            requiresWriteAccess: false
        },
        {
            icon: "fa-solid fa-trash-can",
            color: "red",
            text: "Delete Post",
            onClick: () => {
                handleDeletePost();
            },
            requiresWriteAccess: true
        },
    ]

    return (
    <div className="post">
        { showOptionsModal ?
            <PopupOptionsMenu
                user={user}
                toggleShowPopupOptions={toggleOptionsModal}
                    buttons={postMenuOptions}
            /> : <></>
        }
        <Container fluid>
            <Row>
                <Col xs="12">
                    <div className='post-container'>
                        { hideMenu ? <></> : <div className='menu-icon' onClick={toggleOptionsModal}><FontAwesomeIcon icon="fa-solid fa-ellipsis" /></div>}
                    <div class="post-category">
                        <div class={`post-category-circle color-${color}`}></div>
                        <div
                            onClick={clickable ? (() => { goToHobbyPage() }) : (() => {})}
                        >{category}</div>
                    </div>
                    { imageUrl ?
                        <div className="post-image">
                            <img src={imageUrl} alt="Post" />
                        </div>
                    : <></>}
                    <div className="post-title">{title}</div>
                    <p className="post-content">
                        {showFullContent ? content : shortenedContent}
                        {content.length > CONTENT_TRUNCATE_LENGTH && !showFullContent && (
                            <span className="primary-text-color" onClick={toggleContent}> Show More</span>
                        )}
                    </p>
                        <div className="post-footer">
                            { isPrivate ? <span><FontAwesomeIcon icon="fa-solid fa-eye-slash" /> Private</span>  : <></>}
                            <span className="post-days">{ createdAt ? getTimeFromNow(createdAt) : "" }</span>
                            <span className="post-location">{ location ? <><FontAwesomeIcon icon="fa-solid fa-location-dot" /> {location}</> : <></>}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Post;
