import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Post from '../components/post';
import AttributeInput from '../components/attributeInput';
import '../style/post.css';
import { useNavigate } from 'react-router-dom';

import { useProfile } from '../assets/data/providerContext';
import { createNewPost } from '../functions/firebase';
import { mpTrack, flattenObjectForMP } from '../functions/util_fn';

import LoadingNotificationBar from '../components/loadingNotificationBar';

function NewPost(props) {

    const [page, setPage] = useState('one');
    const [postInfo, setPostInfo] = useState({});
    const [postContent, setPostContent] = useState(''); // State for textarea content
    const [selectedHobbyIndex, setSelectedHobbyIndex] = useState(null); // State for selected hobby index
    const [showLoadingBar, setShowLoadingBar] = useState({display: false, message:''});
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const profileContext = useProfile();
    const profileInfo = profileContext == null ? null : profileContext.profileInfo;

    useEffect(() => {
        mpTrack('Initated New Post');
    },[])

    // Function for input componenets to add post info to state
    const addPostInfo = (key, value) => {
        setPostInfo((prevState) => {
            const updatedPostInfo = { ...prevState, [key]: value };
            console.log("New Post Info: ", updatedPostInfo);
            return updatedPostInfo;
        });
    }

    const changeFormPage = (page) => {
        if (!isLoading && !showLoadingBar.display) {
            setPage(page);
        }
    }

    const handlePostContentChange = (e) => {
        setPostContent(e.target.value); // Update state with textarea content
    };

    const handleHobbySelect = (hobby, index) => {
        setSelectedHobbyIndex(index);
        addPostInfo('hobby', hobby);
    };

    const getPostDate = () => {
        if(postInfo['Change Date']){
            return new Date(postInfo['Change Date']);
        } else {
            return null;
        }
    }

    const handlePostSubmit = async () => {
        if (!isLoading && !showLoadingBar.display) {
            setIsLoading(true);
            showLoadingMessage('Uploading Post...')

            var compositePostInfo = {};
            // Check if there is any information in post content or post info
            if (postContent === '' && Object.keys(postInfo).length === 1) {
                console.log("No post content or post info");
            } else {
                compositePostInfo.content = postContent;
                compositePostInfo.hobby = postInfo.hobby;
                compositePostInfo.title = postInfo['Add Title'];
                compositePostInfo.imageURL = postInfo['post-photo'];
                compositePostInfo.location = postInfo['Add Location'];
                compositePostInfo.milestone = postInfo['Milestone'];
                compositePostInfo.postDate = getPostDate();
                compositePostInfo.privatePost = postInfo['Private'];
                console.log("Posting new post...", compositePostInfo);
                var successfullyPosted = await createNewPost(props.user.uid, compositePostInfo);
                if (successfullyPosted) {
                    mpTrack("Post Created", flattenObjectForMP(compositePostInfo));
                    await profileContext.refreshProfileInfo();
                    setShowLoadingBar(false);
                    goBack();
                }
            }
            setIsLoading(false);
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    const showLoadingMessage = (message) => {
        setShowLoadingBar({ display: true, message: message });
    }

    const hideLoadingMessage = () => {
        setShowLoadingBar({ display: false, message: '' });
    }

    return (
        <div className={isLoading ? 'loading-overlay' : ''}>
            <>
                { page == 'one' ? 
                <>
                    <div className='page-header-nav'>
                        <Container fluid>
                            <Row>
                                <Col xs="2">
                                    <div className='circ-btn subtext-gray' onClick={goBack}>
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                    </div>
                                </Col>
                                <Col className='title' xs="8">
                                    <span>New Post</span>
                                </Col>
                                <Col xs="2">
                                    <div className='primary-text-color'>
                                        <p onClick={() => {
                                            if(selectedHobbyIndex !== null) {
                                                setPage('two');
                                            }
                                        }}>Next</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container fluid className='content-below-nav'>
                        <p>Select Hobby</p>
                        <div className='hobby-list-short'>
                            {profileInfo ? profileInfo.hobbies.map((hobby, index) => (
                            <Row 
                                key={index} 
                                className={`hobby-row ${selectedHobbyIndex === index ? 'selected' : ''}`} 
                                onClick={() => handleHobbySelect(hobby, index)}
                            >
                                <Col xs="2" className="icon-col">
                                    <div className={`hobby-category-circle color-${hobby.color_index || '1'} ${selectedHobbyIndex === index ? 'selected' : ''}`}></div>
                                </Col>
                                <Col xs="9" className="text-col">
                                <div className="hobby-name">{hobby.hobbyName}</div>
                                </Col>
                            </Row>
                            )) : <></>}
                        </div>
                        <p style={{marginTop: '16px'}}>Post</p>
                        <textarea 
                            placeholder="Write your thoughts here..."
                            value={postContent} // Bind state to textarea value
                            onChange={handlePostContentChange} // Handle change event
                            
                        />
                    </Container>
                </>
                : <>
                    <div className='page-header-nav'>
                        <Container fluid>
                            <Row>
                                <Col xs="2">
                                    <div className={`circ-btn subtext-gray ${isLoading ? 'disabled' : ''}`} 
                                         onClick={() => !isLoading && changeFormPage('one')}>
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                                    </div>
                                </Col>
                                <Col className='title' xs="8">
                                    <span>New Post</span>
                                </Col>
                                <Col xs="2">
                                    <div className={`circ-btn primary-text-color ${isLoading ? 'disabled' : ''}`}>
                                        <p onClick={() => !isLoading && handlePostSubmit()}>Share</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    { showLoadingBar.display ? <LoadingNotificationBar notificationMessage={showLoadingBar.message}/> : <></> }
                    <Container fluid className={`content-below-nav ${ showLoadingBar.display ? "remove-top-margin" : "" }`}>
                        <Row>
                            <Col xs="12">
                                <Post
                                    content={postContent}
                                    title={postInfo['Add Title'] ? postInfo['Add Title'] : null}
                                    category={postInfo.hobby ? postInfo.hobby.hobbyName : ''}
                                    color="1"
                                    location={postInfo['Add Location'] ? postInfo['Add Location'] : null}
                                    imageUrl={postInfo['post-photo'] ? postInfo['post-photo'] : null}
                                    hideMenu={true}
                                    isPrivate={postInfo['Private']}
                                />
                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Add Title" 
                                    icon="fa-font" 
                                    type="text"
                                    initialValue={postInfo['Add Title']}
                                />
                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Add Photo" 
                                    icon="fa-image" 
                                    type="image" 
                                    user={props.user} 
                                    showLoadingMessage={showLoadingMessage} 
                                    hideLoadingMessage={hideLoadingMessage}
                                    initialValue={postInfo['post-photo']}
                                />
                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Add Location" 
                                    icon="fa-location-dot" 
                                    type="text"
                                    initialValue={postInfo['Add Location']}
                                />
                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Change Date" 
                                    icon="fa-calendar-week" 
                                    type="date"
                                    initialValue={postInfo['Change Date']}
                                />
                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Milestone" 
                                    icon="fa-star" 
                                    type="checkbox"
                                    initialValue={postInfo['Milestone']}
                                />
                                <AttributeInput 
                                    addPostInfo={addPostInfo} 
                                    title="Private" 
                                    icon="fa-eye" 
                                    type="checkbox"
                                    initialValue={postInfo['Private']}
                                />
                            </Col>
                        </Row>
                    </Container>
                </>
                }
            </>
        </div>
    );
}

export default NewPost;
