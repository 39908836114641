import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

function FriendList({ friends }) {
    return (
        <div className='hobby-friend-list'>
            <Container>
                <Row>
                    <Col xs="12">
                        <h3>Disc Golf Friends</h3>
                    </Col>
                </Row>
                {friends.map((friend, index) => (
                    <div className='friend' key={index}>
                        <Row>
                            <Col className='right-align' xs="2">
                                <div className='hobby-category-circle' style={{
                                    backgroundImage: `url(${friend.profilePic})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    width: '35px',
                                    height: '35px',
                                }}></div>
                            </Col>
                            <Col xs="10">
                                <p>{friend.name}</p>
                            </Col>
                        </Row>
                    </div>
                ))}
                <div className='post'>
                    <Row>
                        <Col xs="12">
                            <span style={{color:'#1799F6'}}>See All Friends</span>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default FriendList; 