import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function LoadingNotificationBar({ notificationMessage}) {
    return (
        <div className='loading-notification-bar'>
            <Container fluid>
                <Row>
                    <Col xs="12">
                        <span><FontAwesomeIcon spin icon="fa-solid fa-spinner" /> {notificationMessage}</span>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LoadingNotificationBar; 