import React from 'react';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom';
import { getTimeFromNow } from '../functions/util_fn';

import '../style/post.css';
import starBlue from '../assets/images/star-blue.png';

function MilestonePost({ user, title, content, createdAt, location, color, category, imageUrl, clickable, hobbyID, isPrivate }) {
  
    const navigate = useNavigate();
    var URLlocation = useLocation();

    const goToHobbyPage = () => {
        const targetUrl = `/u/${user.uid}/hobby/${hobbyID}`;
        
        // Check if the current URL is different from the target URL
        if (URLlocation.pathname !== targetUrl) {
            navigate(targetUrl, {
                state: {
                    "origin_url": URLlocation.pathname
                }
            });
        } else {
            console.log('Already on the target URL');
        }
    }

    return (
    <>
    <div className='post milestone-post-category'>
        <Container fluid>
            <Row>
                <Col xs="12">
                    <div className='post-container'>
                        <div className='menu-icon'><FontAwesomeIcon icon="fa-solid fa-ellipsis" /></div>
                        <div class="post-category flush-bottom">
                            <div class={`post-category-circle color-${color}`}></div>
                            <div
                                onClick={clickable ? (() => { goToHobbyPage() }) : (() => {})}
                            >{category}</div>
                        </div>                
                    </div>
                
                </Col>
            </Row>
        </Container>
    </div>
    <div className="post post-milestone">
        <Container fluid>
            <Row>
                <Col xs="12">
                    <div className='post-container'>
                        { imageUrl ?
                            <div className='milestone-img-holder'>
                                <div className="post-image">
                                    <img src={imageUrl} alt="Post" />
                                </div>
                                <div className='icon-holder'>
                                    <div className='icon-circle'>
                                        <img src={starBlue} alt="User" />
                                    </div>
                                </div>
                            </div>
                        :
                        <div className='vert-spacer'></div>
                        }
                        <div className='milestone-content'>
                            
                            <div className='milestone-tag'>Milestone</div>
                            <div className="post-title">{title}</div>
                            <p className="post-content">{content}</p>
                            <div className="post-footer">
                                { isPrivate ? <span><FontAwesomeIcon icon="fa-solid fa-eye-slash" /> Private</span>  : <></>}
                                <span className="post-days">{ createdAt ? getTimeFromNow(createdAt) : "" }</span>
                                <span className="post-location">{ location ? <><FontAwesomeIcon icon="fa-solid fa-location-dot" /> {location}</> : <></>}</span>
                            </div>
                        </div>
                    
                    </div>
                
                </Col>
            </Row>
        </Container>
    </div>
    </>
  );
}

export default MilestonePost;
