import React, { createContext, useContext } from 'react';

// Create a context for the profile data
const ProfileContext = createContext();

// Hook to use the profile context
export const useProfile = () => useContext(ProfileContext);

// ProfileProvider component to wrap the context provider
export const ProfileProvider = ({ value, children }) => {
    return (
        <ProfileContext.Provider value={value}>
            {children}
        </ProfileContext.Provider>
    );
};