import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { createNewUserAccount, signInUser } from '../functions/firebase';
import { mpTrack } from '../functions/util_fn';

function AuthForm({ onAuthSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(true);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setValidationError('');

    // Validate email and password are not empty
    if (!email || !password) {
      setValidationError('Please fill in both email and password fields');
      return;
    }

    try {
      if (isSignUp) {
        const authResult = await createNewUserAccount(email, password);
        if (authResult.successfulSignup) {
          mpTrack('User Signed Up', { '$email': email, uid: authResult.user.uid });
          onAuthSuccess(authResult.user);
        } else {
          setError(authResult.error);
        }
      } else {
        const authResult = await signInUser(email, password);
        if (authResult.successfulLogin) {
          mpTrack('User Signed In', { '$email': email, uid: authResult.user.uid });
          onAuthSuccess(authResult.user);
        } else {
          setError(authResult.error);
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="">
      <Form.Group controlId="formBasicEmail" className="mb-2">
        <Form.Label className="text-white">Email address</Form.Label>
        <Form.Control 
          type="email" 
          placeholder="Enter email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          className="p-3"
        />
      </Form.Group>

      <Form.Group controlId="formBasicPassword" className="mb-4">
        <Form.Label className="text-white">Password</Form.Label>
        <Form.Control 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)}
          className="p-3" 
          autoComplete="current-password"
        />
      </Form.Group>

      {error && <p className="text-danger mb-4">{error}</p>}
      {validationError && <p className="text-danger mb-4">{validationError}</p>}

      <div className="d-grid gap-3">
        <Button 
          variant="primary" 
          type="submit"
          className="primary-btn-fill py-3"
        >
          {isSignUp ? 'Create an Account' : 'Sign In'}
        </Button>
        <Button 
          variant="outline-secondary" 
          onClick={() => setIsSignUp(!isSignUp)}
          className="text-center text-white"
          style={{ borderColor: 'white' }}
        >
          {isSignUp ? 'Sign In' : 'Create an Account'}
        </Button>
      </div>
    </Form>
  );
}

export default AuthForm;