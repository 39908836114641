import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

function GenericList({ title, items }) {
    return (
        <div>
            <Container>
                <div className='post'>
                    <Row>
                        <Col xs="12">
                            <h3>{title}</h3>
                            {items.filter(item => item.name).map((item, index) => (
                                <div key={index}>
                                    <p>{item.name}</p>
                                    {item.imageUrl && (
                                        <img 
                                            src={item.imageUrl} 
                                            style={{'borderRadius':'15px', 'maxWidth': '100%', 'height': 'auto'}} 
                                            alt={item.name} 
                                        />
                                    )}
                                    {item.linkUrl && (
                                        <a href={item.linkUrl} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                            <div className='secondary-wide-btn'>View Item</div>
                                        </a>
                                    )}
                                    <br />
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default GenericList;