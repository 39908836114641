import React from 'react';
import { Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import FriendList from './hobbyPage/friendsList';
import GenericList from './hobbyPage/GenericList';
import '../style/hobbies.css';

function AboutHobby({ hobbyHeader }) {
  const hasContent = hobbyHeader.startDate || hobbyHeader.aboutText;
    console.log(hobbyHeader);
  return (
    <div className='content-below-nav remove-top-margin'>
      {hasContent ? (
        <div className='about-hobby-top'>
          <Container>
            <Row>
              <Col xs="12">
                <span>Interested since</span>
                <h2>
                  {hobbyHeader.startDate ? 
                    new Date(hobbyHeader.startDate).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    }) 
                    : 'Not specified'}
                </h2>
                {hobbyHeader.aboutImageUrl && (
                  <img src={hobbyHeader.aboutImageUrl} alt="Hobby" />
                )}
                {hobbyHeader.aboutText && hobbyHeader.aboutText.split('\n').map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className='about-hobby-top content-below-nav'>
          <Container>
            <Row>
              <Col xs="12" className="text-center">
                <h2>More to come...</h2>
                <p>This story is still being written</p>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {hobbyHeader.friends && <FriendList friends={hobbyHeader.friends} />}
      {hobbyHeader.gear && hobbyHeader.gear.length > 0 && <GenericList title="Gear" items={hobbyHeader.gear} />}
    </div>
  );
}

export default AboutHobby;
