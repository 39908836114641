import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useProfile } from '../assets/data/providerContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { handleShareLink, allowWriteAccess, mpTrack } from '../functions/util_fn';

function ProfileHeader(props) {

    const profileContext = useProfile();
    const profileInfo = profileContext == null ? null : profileContext.profileInfo;

    const navigate = useNavigate();

    const goToEditProfilePage = () => {
        navigate(`/u/${props.user.uid}/edit`);
    }

    const shareProfile = async () => {
        const successfullyCopied = await handleShareLink();
        if(successfullyCopied){
            mpTrack("Profile Shared", { url: window.location.href });
            showCopyLinkToast();
        }
    }

    const showCopyLinkToast = () => {
        toast("Copied link", {
            autoClose: 3000,
            hideProgressBar: false,
        } );
    }


  return (
      <header className='profile-header'>
        <Container fluid>
            <Row>
                <Col xs="5">
                    {/* <img className='circ-image' src={profileInfo ? profileInfo.profile_pic_url : ""} alt="Logo" /> */}
                    { profileInfo == null ? <div></div> :
                        <div style={{
                            width: '132px', // Set the desired width and height to the same value
                            height: '132px',
                            backgroundImage: `url(${profileInfo.profile_pic_url})`,
                            backgroundSize: 'cover', // Cover the entire div
                            backgroundPosition: 'center', // Center the image
                            backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                            borderRadius: '50%' // Make the div a circle    
                        }}></div>
                    }
                    
                </Col>
                <Col xs="7">
                    {profileInfo == null ? <div></div> :
                    <>
                    <Row>
                        <Col xs="12">
                            <h1>{profileInfo.displayName}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                        {profileInfo.hobbies.length} {profileInfo.hobbies.length === 1 ? "Hobby" : "Hobbies"} <span className='dot-spacer'><FontAwesomeIcon icon="fa-solid fa-circle" /></span>
                        {profileInfo.all_posts_count} posts  
                        </Col>
                        <Col xs="12" className='subtext-gray'>{profileInfo.location}</Col>
                    </Row>
                    </>}
                </Col>
            </Row>
            { allowWriteAccess(props.user) ?
                <Row>
                    <Col xs="6">
                        <div className='secondary-wide-btn' onClick={()=>{goToEditProfilePage()}}>Edit Profile</div>
                    </Col>
                    <Col xs="6">
                        <div className='secondary-wide-btn' onClick={()=> {shareProfile()}}>Share Profile</div>
                    </Col>
                </Row>
            : <></>}
            
        </Container>
      </header>
  );
}

export default ProfileHeader;
