import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faGuitar, faCamera, faHiking, faDumbbell, 
         faGamepad, faBook, faUtensils, faSeedling, faPlaneDeparture,
         faBicycle, faChess, faPaintBrush, faVolleyballBall, faRunning,
         faMicrophone, faDice, faFootballBall, faPencilAlt, faYinYang } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const ScrollingContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background: transparent;
  padding: 20px 0;
`;

const IconStrip = styled.div`
  display: flex;
  gap: 40px;
  animation: scroll 14s linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const IconWrapper = styled.div`
  font-size: 34px;
  color: #13B2B3;
  flex-shrink: 0;
`;

const HobbyIconCarousel = () => {
  const icons = [
    faPalette, faGuitar, faCamera, faHiking, faDumbbell,
    faGamepad, faBook, faUtensils, faSeedling, faPlaneDeparture,
    faBicycle, faChess, faPaintBrush, faVolleyballBall, faRunning,
    faMicrophone, faDice, faFootballBall, faPencilAlt, faYinYang,
    faPalette, faGuitar, faCamera, faHiking, faDumbbell,
    faGamepad, faBook, faUtensils, faSeedling, faPlaneDeparture,
    faBicycle, faChess, faPaintBrush, faVolleyballBall, faRunning,
    faMicrophone, faDice, faFootballBall, faPencilAlt, faYinYang,
    faPalette, faGuitar, faCamera, faHiking, faDumbbell,
    faGamepad, faBook, faUtensils, faSeedling, faPlaneDeparture,
    faBicycle, faChess, faPaintBrush, faVolleyballBall, faRunning,
    faMicrophone, faDice, faFootballBall, faPencilAlt, faYinYang
  ];

  // Duplicate icons to create seamless loop
  const allIcons = [...icons, ...icons];

  return (
    <ScrollingContainer>
      <IconStrip>
        {allIcons.map((icon, index) => (
          <IconWrapper key={index}>
            <FontAwesomeIcon icon={icon} />
          </IconWrapper>
        ))}
      </IconStrip>
    </ScrollingContainer>
  );
};

export default HobbyIconCarousel;
